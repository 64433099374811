<template>
  <login-layout
    page-class="temp-code-login"
    pageTitle="Login with code"
    icon="user"
  >
    <div class="form-block-wrapper">
      <VeeForm
        @submit="submitForm"
        ref="loginWithCode"
        v-slot="{ errors }"
        class="form"
        novalidate
      >
        <transition name="fade">
          <div class="server-error error-message" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </transition>
        <transition name="fade">
          <div class="success-message error-message" v-if="message">
            {{ message }}
          </div>
        </transition>
        <div class="form-group input temp_code">
          <Field
            name="tmp_code"
            as="input"
            type="text"
            rules="required|numeric"
            :placeholder="
              displayLabelName('visitor-management', 'registration', 'code')
            "
            :disabled="submitting"
          />
          <span class="error-message" v-if="errors.tmp_code">
            {{ errors.tmp_code }}
          </span>
        </div>
        <div class="form-group input">
          <Field
            name="uuid"
            as="input"
            type="hidden"
            rules="required"
            placeholder="uuid"
            :value="this.$route.query.uuid"
          />
        </div>
        <div class="form-group">
          <button
            :class="submitting ? 'btn primary disabled' : 'btn primary'"
            :disabled="submitting"
            type="submit"
          >
            {{
              displayLabelName(
                "visitor-management",
                "registration",
                "login-with-code"
              )
            }}
          </button>
        </div>
        <div class="form-group submit">
          <button
            class="btn primary"
            type="button"
            @click="sendCode"
            :disabled="sendingCode"
          >
            {{
              displayLabelName(
                "visitor-management",
                "registration",
                "send-code-again"
              )
            }}
          </button>
        </div>
      </VeeForm>
    </div>
  </login-layout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout";
import { mapActions, mapState } from "vuex";
export default {
  name: "forgot-password",
  components: {
    LoginLayout
  },
  data() {
    return {
      submitting: false,
      message: null,
      sendingCode: false
    };
  },
  created() {
    this.message = null;
    if (!this.codeSignIn) {
      this.sendCode();
    }
  },
  computed: {
    ...mapState("auth", ["errorMessage", "codeSignIn"])
  },
  methods: {
    ...mapActions("auth", ["loginWithCode", "sendTempCode"]),
    submitForm(values) {
      this.message = null;
      this.$store.commit("auth/setErrorMessage", null, { root: true });
      if (values) {
        this.submitting = true;
        this.sendingCode = true;
        this.loginWithCode(values).finally(() => {
          this.submitting = false;
          this.sendingCode = false;
        });
      }
    },
    sendCode() {
      this.sendingCode = true;
      this.sendTempCode(this.$route.query.uuid).then(response => {
        this.message = response.message;
        this.$store.commit("auth/setCodeSignIn", true, { root: true });

        /**
         * Disable "send code" button for additional second.
         * TODO: After submitting this button should show some countdown timer until next enabling
         *  because we want to restrain mass sending
         */
        setTimeout(() => {
          this.sendingCode = false;
        }, 1000);
      });
    }
  }
};
</script>
